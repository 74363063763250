import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { AppSettingsProvider } from '@app/app/app.settings';
import { DaySection, FitnessCenter } from '@app/interfaces';

@Injectable()
export class ServerProvider {
  private centers$ = new BehaviorSubject<FitnessCenter[]>([]);
  private _currentCenter: FitnessCenter | null = null;
  private processing$ = new BehaviorSubject<boolean>(false);

  constructor(private appSettings: AppSettingsProvider, private http: HttpClient) {}

  get processing(): Observable<boolean> {
    return this.processing$;
  }

  get centers(): Observable<FitnessCenter[]> {
    return this.centers$;
  }

  get currentCenter(): FitnessCenter | null {
    return this._currentCenter;
  }

  setCurrentCenter(center: FitnessCenter): void {
    this._currentCenter = center;
  }

  loadCenters(): Promise<void> {
    this.processing$.next(true);
    return new Promise((resolve, reject) =>
      this.appSettings.get().then((settings) => {
        this.http
          .post(settings.server + '/xml?format=json', {
            xml: `
              <Message>
                <MessageName>ActivationCodeFind</MessageName>
                <ComponentName>FC.CheckInSystem</ComponentName>
                <Parameters>
                  <ActivationCodeGUID>${settings.activationCode}</ActivationCodeGUID>
                  <Version></Version>
                </Parameters>
              </Message>
            `,
          })
          .subscribe({
            next: (result: any) => {
              if (result.Data && result.Data.List.ListItem) {
                if (result.Data.List.ListItem instanceof Array) {
                  this.centers$.next(result.Data.List.ListItem);
                } else {
                  this.centers$.next([result.Data.List.ListItem]);
                }
              }
            },
            error: (error) => reject(error),
            complete: () => {
              resolve();
              this.processing$.next(false);
            },
          });
      })
    );
  }

  getAllSessions(daySections: Array<DaySection>, center: FitnessCenter, dateModifier: number): Promise<Array<DaySection>> {
    return new Promise((resolve) => {
      let sessionPromises: Array<Promise<any>> = [];
      daySections.forEach((daySection) => {
        let xml: string;
        if (center.ActivationType === '2') {
          xml = `
            <Message>
              <MessageName>WebAppTrainingSessionsGet</MessageName>
              <ComponentName>FC.CheckInSystem</ComponentName>
              <Parameters>
                <FitnessCenterGUID>${center.ActivatedGUID}</FitnessCenterGUID>
                <Date>${this.dateString(daySection, dateModifier, daySections.length)}</Date>
              </Parameters>
            </Message>
          `;
        } else {
          xml = `
            <Message>
              <MessageName>RetentionUserNotificationCalender</MessageName>
              <ComponentName>FC.Retention</ComponentName>
              <Parameters>
                <FitnessCenterUserGUID>${center.ActivatedGUID}</FitnessCenterUserGUID>
                <NotificationDate>${this.dateString(daySection, dateModifier, daySections.length)}</NotificationDate>
              </Parameters>
            </Message>
          `;
        }
        sessionPromises.push(this.getSessions(xml, daySection));
      });
      Promise.all(sessionPromises)
        .then((daySectionsWithSessions) => resolve(daySectionsWithSessions))
        .catch((err) => console.log(err));
    });
  }

  book(guid: string, date: string, pin?: string, id?: string): Promise<any> {
    return new Promise((resolve, reject) =>
      this.appSettings.get().then((settings) => {
        const xml: string = (() => {
          if (settings.user && settings.userLogin) {
            return `
              <Message>
                <MessageName>TrainingSessionBook</MessageName>
                <ComponentName>FC.CheckInSystem</ComponentName>
                <Parameters>
                  <TrainingSessionGUID>${guid}</TrainingSessionGUID>
                  <UserGUID>${settings.user}</UserGUID>
                  <UserLoginGUID>${settings.userLogin}</UserLoginGUID>
                  <Date>${date}</Date>
                  <BookingApplicationGUID></BookingApplicationGUID>
                  <Version>1</Version>
                </Parameters>
              </Message>
            `;
          } else {
            return `
              <Message>
                <MessageName>WebAppTrainingSessionBook</MessageName>
                <ComponentName>FC.CheckInSystem</ComponentName>
                <Parameters>
                  <TrainingSessionGUID>${guid}</TrainingSessionGUID>
                  <Date>${date}</Date>
                  <PinCode>${pin}</PinCode>
                  <ID>${id}</ID>
                  <BookingApplicationGUID></BookingApplicationGUID>
                </Parameters>
              </Message>
            `;
          }
        })();
        this.http.post(settings.server + '/xml?format=json', { xml }).subscribe({
          next: (res) => {
            resolve(res);
          },
          error: (error) => {
            console.dir(error);
            reject();
          },
        });
      })
    );
  }

  private dateString(day: DaySection, dateModifier: number, sectionAmount: number): string {
    const amount = dateModifier * sectionAmount;
    let dateString: string;
    let date = new Date();
    if (day.modifier === 'plus') {
      date.setDate(date.getDate() + day.day + amount);
    } else if (day.modifier === 'minus') {
      date.setDate(date.getDate() - day.day + amount);
    }
    const month = (): string => {
      let d: string = (date.getMonth() + 1).toString();
      if (d.length === 1) {
        return '0' + d;
      } else {
        return d;
      }
    };
    const monthDate = (): string => {
      let d: string = date.getDate().toString();
      if (d.length === 1) {
        return '0' + d;
      } else {
        return d;
      }
    };
    dateString = date.getFullYear() + '-' + month() + '-' + monthDate();
    return dateString;
  }

  private getSessionLimits(session: any): any {
    const sessionStartHour = parseInt(session.StartTime.substring(0, session.StartTime.indexOf(':')));
    const sessionStartMinutes = parseInt(session.StartTime.substring(session.StartTime.indexOf(':') + 1, session.StartTime.length));
    const sessionStart = new Date(session.Date).setHours(sessionStartHour, sessionStartMinutes, 0, 0);
    const sessionEndHour = parseInt(session.EndTime.substring(0, session.EndTime.indexOf(':')));
    const sessionEndMinutes = parseInt(session.EndTime.substring(session.EndTime.indexOf(':') + 1, session.EndTime.length));
    const sessionEnd = new Date(session.Date).setHours(sessionEndHour, sessionEndMinutes, 0, 0);
    return {
      start: sessionStart,
      end: sessionEnd,
    };
  }

  private getSessionHeight(session: any): Promise<number> {
    return new Promise((resolve, reject) =>
      setTimeout(() => {
        const limits = this.getSessionLimits(session);
        const sessionLength = limits.end - limits.start;
        resolve((sessionLength / 300000) * 17);
      }, 4)
    );
  }

  private setSessionData(sessions: Array<any>): Promise<Array<any>> {
    return new Promise((resolve) => {
      let worker = new Worker('assets/workers/session-data.worker.js?v=' + new Date().getTime());
      worker.onmessage = (event) => {
        resolve(event.data.sessions);
        worker.terminate();
      };
      worker.postMessage({ sessions: sessions });
    });
  }

  private getSessions(xml: string, daySection: DaySection): Promise<any> {
    return new Promise((resolve, reject) =>
      this.appSettings.get().then((settings) => {
        this.http
          .post(settings.server + '/xml?format=json', {
            xml: xml,
          })
          .subscribe((result: any) => {
            if (result.Data?.List?.ListItem) {
              if (result.Data.List.ListItem instanceof Array) {
                this.setSessionData(result.Data.List.ListItem).then((sessions) => {
                  daySection.sessions = sessions;
                  resolve(daySection);
                  result = null;
                });
              } else {
                this.getSessionHeight(result.Data.List.ListItem).then((height) => {
                  result.Data.List.ListItem.Height = height;
                  result.Data.List.ListItem.RowSize = 1;
                  result.Data.List.ListItem.Position = 0;
                  result.Data.List.ListItem.RowOffset = 0;
                  daySection.sessions = [];
                  daySection.sessions.push(result.Data.List.ListItem);
                  resolve(daySection);
                  result = null;
                });
              }
            } else {
              daySection.sessions = [];
              resolve(daySection);
              result = null;
            }
          });
      })
    );
  }
}
