import { HttpClient } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';

import { AppSettings } from '@app/interfaces';

@Injectable()
export class AppSettingsProvider {
  private settings: AppSettings = {
    server: null,
    authServer: null,
    registerServer: null,
    activationCode: null,
    language: 'en-US',
    auth: null,
    user: null,
    userLogin: null,
  } as unknown as AppSettings;

  constructor(private http: HttpClient, private ngZone: NgZone) {}

  private getParams(): string | null {
    if (location.href.indexOf('?') > 0) {
      return location.href.substring(location.href.indexOf('?') + 1, location.href.length);
    } else {
      return null;
    }
  }

  instantiate(): Promise<void> {
    return new Promise((resolve, reject) =>
      this.ngZone.run(() => {
        let params = this.getParams();
        if (params) {
          const parsed: Record<string, unknown> = params
            ? JSON.parse('{"' + params.replace(/&/g, '","').replace(/=/g, '":"') + '"}', function (key, value) {
                return key === '' ? value : decodeURIComponent(value);
              })
            : {};
          if (parsed.a) {
            this.settings.activationCode = parsed.a as string;
            if (parsed.l) {
              this.settings.language = parsed.l as string;
            }
            if (parsed.u) {
              this.settings.user = parsed.u as string;
            }
            if (parsed.q) {
              this.settings.auth = true;
            }
            if (parsed.lg) {
              this.settings.userLogin = parsed.lg as string;
            }
            resolve();
          } else {
            reject();
          }
        } else {
          reject();
        }
      })
    );
  }

  get(): Promise<AppSettings> {
    return new Promise((resolve, reject) => {
      if (!this.settings.server) {
        this.http.get(`./assets/settings.json?d=${new Date().getTime().toString()}`).subscribe((settings: any) => {
          this.settings.server = settings.server;
          this.settings.authServer = settings.authServer;
          this.settings.registerServer = settings.registerServer;
          resolve(this.settings);
        });
      } else {
        resolve(this.settings);
      }
    });
  }
}
