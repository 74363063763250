import { HttpClientModule } from '@angular/common/http';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';

import { CanAccessSessionsGuard } from '@app/guards';
import { SelectCenterComponent } from '@app/pages/select-center/components';
import { SelectCenterContainer } from '@app/pages/select-center/containers';
import { TrainingSessionsComponent } from '@app/pages/training-sessions/training-sessions.component';
import { ServerProvider, AuthProvider, ContextMenuProvider, SelectedSessionProvider } from '@app/providers';
import {
  AdvertisementDialogComponent,
  FitnessCenterPickerComponent,
  TrainingSessionDialogComponent,
  DateRangePickerComponent,
  ContextMenuComponent,
} from '@app/shared/components';
import { SessionsWithinTimeSpanPipe, SessionsWithinTimeSpanV2Pipe, SubtractPipe, ToNumberPipe } from '@app/shared/pipes';

import { AppRoutesModule } from './app-routes.module';
import { AppComponent } from './app.component';
import { AppSettingsProvider } from './app.settings';

export function appInitFactory(appSettings: AppSettingsProvider, auth: AuthProvider, server: ServerProvider) {
  return (): Promise<void> => {
    return new Promise((resolve) => {
      appSettings
        .instantiate()
        .then(() => auth.init())
        .then(() => server.loadCenters())
        .then(() => resolve())
        .catch(() => alert('Wrong URL given'));
    });
  };
}

@NgModule({
  declarations: [
    AppComponent,
    TrainingSessionsComponent,
    ContextMenuComponent,
    FitnessCenterPickerComponent,
    DateRangePickerComponent,
    SessionsWithinTimeSpanPipe,
    SessionsWithinTimeSpanV2Pipe,
    SubtractPipe,
    ToNumberPipe,
    TrainingSessionDialogComponent,
    AdvertisementDialogComponent,
    SelectCenterContainer,
    SelectCenterComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutesModule,
    FlexLayoutModule,
    RouterModule,
    HttpClientModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatDialogModule,
    MatInputModule,
    MatFormFieldModule,
    MatSnackBarModule,
    MatDividerModule,
  ],
  providers: [
    AppSettingsProvider,
    AuthProvider,
    ServerProvider,
    ContextMenuProvider,
    SelectedSessionProvider,
    CanAccessSessionsGuard,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitFactory,
      deps: [AppSettingsProvider, AuthProvider, ServerProvider],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
