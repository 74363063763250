import { PipeTransform, Pipe } from '@angular/core';

import { DaySection, TimeSegment } from '@app/interfaces';
import { DaySections } from '@app/shared/constants/daysections';

@Pipe({
  name: 'sessionsWithinTimeSpanV2',
})
export class SessionsWithinTimeSpanV2Pipe implements PipeTransform {
  private daySections: Array<DaySection> = DaySections;

  transform(sessions: Array<any>, timeSegment: TimeSegment, daySection: DaySection, dateModifier: number) {
    let validSessions: Array<any> = [];
    sessions.forEach((session) => {
      if (this.sessionIsWithinTimespan(session, timeSegment, daySection, dateModifier)) {
        validSessions.push(session);
      }
    });
    return validSessions;
  }

  private sessionIsWithinTimespan(session: any, timeSegment: TimeSegment, daySection: DaySection, dateModifier: number): boolean | null {
    const amount = dateModifier * this.daySections.length;
    let timeSegmentStart: any = new Date();
    let timeSegmentEnd: any = new Date();
    if (daySection.modifier === 'plus') {
      timeSegmentStart.setDate(timeSegmentStart.getDate() + daySection.day + amount);
      timeSegmentEnd.setDate(timeSegmentEnd.getDate() + daySection.day + amount);
    } else if (daySection.modifier === 'minus') {
      timeSegmentStart.setDate(timeSegmentStart.getDate() - daySection.day + amount);
      timeSegmentEnd.setDate(timeSegmentEnd.getDate() - daySection.day + amount);
    }
    timeSegmentStart.setHours(timeSegment.timeRange.start[0], timeSegment.timeRange.start[1], 0, 0);
    timeSegmentEnd.setHours(timeSegment.timeRange.end[0], timeSegment.timeRange.end[1], 0, 0);
    timeSegmentStart = timeSegmentStart.getTime();
    timeSegmentEnd = timeSegmentEnd.getTime();
    const sessionStartYear: number = parseInt(session.Date.substring(0, 4));
    const sessionStartMonth: number = parseInt(session.Date.substring(5, 7)) - 1;
    const sessionStartDay: number = parseInt(session.Date.substring(8, session.Date.length));
    const sessionStartHour: number = parseInt(session.StartTime.substring(0, session.StartTime.indexOf(':')));
    const sessionStartMinutes: number = parseInt(session.StartTime.substring(session.StartTime.indexOf(':') + 1, session.StartTime.length));
    const sessionStart = new Date(
      sessionStartYear,
      sessionStartMonth,
      sessionStartDay,
      sessionStartHour,
      sessionStartMinutes,
      0,
      0
    ).getTime();
    const sessionEndYear: number = parseInt(session.Date.substring(0, 4));
    const sessionEndMonth: number = parseInt(session.Date.substring(5, 7)) - 1;
    const sessionEndDay: number = parseInt(session.Date.substring(8, session.Date.length));
    const sessionEndHour: number = parseInt(session.EndTime.substring(0, session.EndTime.indexOf(':')));
    const sessionEndMinutes: number = parseInt(session.EndTime.substring(session.EndTime.indexOf(':') + 1, session.EndTime.length));
    const sessionEnd = new Date(sessionEndYear, sessionEndMonth, sessionEndDay, sessionEndHour, sessionEndMinutes, 0, 0).getTime();
    if (timeSegmentStart >= sessionStart && sessionEnd >= timeSegmentEnd) {
      return true;
    } else {
      return null;
    }
  }
}
