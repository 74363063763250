import { TimeSegment } from '@app/interfaces';

export const TimeSegments: Array<TimeSegment> = [
  {
    label: '00:00',
    visible: true,
    timeRange: {
      start: [0, 0],
      end: [0, 4]
    }
  },
  {
    label: '00:05',
    visible: false,
    timeRange: {
      start: [0, 5],
      end: [0, 9]
    }
  },
  {
    label: '00:10',
    visible: false,
    timeRange: {
      start: [0, 10],
      end: [0, 14]
    }
  },
  {
    label: '00:15',
    visible: false,
    timeRange: {
      start: [0, 15],
      end: [0, 19]
    }
  },
  {
    label: '00:20',
    visible: false,
    timeRange: {
      start: [0, 20],
      end: [0, 24]
    }
  },
  {
    label: '00:25',
    visible: false,
    timeRange: {
      start: [0, 25],
      end: [0, 29]
    }
  },
  {
    label: '00:30',
    visible: true,
    timeRange: {
      start: [0, 30],
      end: [0, 34]
    }
  },
  {
    label: '00:35',
    visible: false,
    timeRange: {
      start: [0, 35],
      end: [0, 39]
    }
  },
  {
    label: '00:40',
    visible: false,
    timeRange: {
      start: [0, 40],
      end: [0, 44]
    }
  },
  {
    label: '00:45',
    visible: false,
    timeRange: {
      start: [0, 45],
      end: [0, 49]
    }
  },
  {
    label: '00:50',
    visible: false,
    timeRange: {
      start: [0, 50],
      end: [0, 54]
    }
  },
  {
    label: '00:55',
    visible: false,
    timeRange: {
      start: [0, 55],
      end: [0, 59]
    }
  },
  {
    label: '01:00',
    visible: true,
    timeRange: {
      start: [1, 0],
      end: [1, 4]
    }
  },
  {
    label: '01:05',
    visible: false,
    timeRange: {
      start: [1, 5],
      end: [1, 9]
    }
  },
  {
    label: '01:10',
    visible: false,
    timeRange: {
      start: [1, 10],
      end: [1, 14]
    }
  },
  {
    label: '01:15',
    visible: false,
    timeRange: {
      start: [1, 15],
      end: [1, 19]
    }
  },
  {
    label: '01:20',
    visible: false,
    timeRange: {
      start: [1, 20],
      end: [1, 24]
    }
  },
  {
    label: '01:25',
    visible: false,
    timeRange: {
      start: [1, 25],
      end: [1, 29]
    }
  },
  {
    label: '01:30',
    visible: true,
    timeRange: {
      start: [1, 30],
      end: [1, 34]
    }
  },
  {
    label: '01:35',
    visible: false,
    timeRange: {
      start: [1, 35],
      end: [1, 39]
    }
  },
  {
    label: '01:40',
    visible: false,
    timeRange: {
      start: [1, 40],
      end: [1, 44]
    }
  },
  {
    label: '01:45',
    visible: false,
    timeRange: {
      start: [1, 45],
      end: [1, 49]
    }
  },
  {
    label: '01:50',
    visible: false,
    timeRange: {
      start: [1, 50],
      end: [1, 54]
    }
  },
  {
    label: '01:55',
    visible: false,
    timeRange: {
      start: [1, 55],
      end: [1, 59]
    }
  },
  {
    label: '02:00',
    visible: true,
    timeRange: {
      start: [2, 0],
      end: [2, 4]
    }
  },
  {
    label: '02:05',
    visible: false,
    timeRange: {
      start: [2, 5],
      end: [2, 9]
    }
  },
  {
    label: '02:10',
    visible: false,
    timeRange: {
      start: [2, 10],
      end: [2, 14]
    }
  },
  {
    label: '02:15',
    visible: false,
    timeRange: {
      start: [2, 15],
      end: [2, 19]
    }
  },
  {
    label: '02:20',
    visible: false,
    timeRange: {
      start: [2, 20],
      end: [2, 24]
    }
  },
  {
    label: '02:25',
    visible: false,
    timeRange: {
      start: [2, 25],
      end: [2, 29]
    }
  },
  {
    label: '02:30',
    visible: true,
    timeRange: {
      start: [2, 30],
      end: [2, 34]
    }
  },
  {
    label: '02:35',
    visible: false,
    timeRange: {
      start: [2, 35],
      end: [2, 39]
    }
  },
  {
    label: '02:40',
    visible: false,
    timeRange: {
      start: [2, 40],
      end: [2, 44]
    }
  },
  {
    label: '02:45',
    visible: false,
    timeRange: {
      start: [2, 45],
      end: [2, 49]
    }
  },
  {
    label: '02:50',
    visible: false,
    timeRange: {
      start: [2, 50],
      end: [2, 54]
    }
  },
  {
    label: '02:55',
    visible: false,
    timeRange: {
      start: [2, 55],
      end: [2, 59]
    }
  },
  {
    label: '03:00',
    visible: true,
    timeRange: {
      start: [3, 0],
      end: [3, 4]
    }
  },
  {
    label: '03:05',
    visible: false,
    timeRange: {
      start: [3, 5],
      end: [3, 9]
    }
  },
  {
    label: '03:10',
    visible: false,
    timeRange: {
      start: [3, 10],
      end: [3, 14]
    }
  },
  {
    label: '03:15',
    visible: false,
    timeRange: {
      start: [3, 15],
      end: [3, 19]
    }
  },
  {
    label: '03:20',
    visible: false,
    timeRange: {
      start: [3, 20],
      end: [3, 24]
    }
  },
  {
    label: '03:25',
    visible: false,
    timeRange: {
      start: [3, 25],
      end: [3, 29]
    }
  },
  {
    label: '03:30',
    visible: true,
    timeRange: {
      start: [3, 30],
      end: [3, 34]
    }
  },
  {
    label: '03:35',
    visible: false,
    timeRange: {
      start: [3, 35],
      end: [3, 39]
    }
  },
  {
    label: '03:40',
    visible: false,
    timeRange: {
      start: [3, 40],
      end: [3, 44]
    }
  },
  {
    label: '03:45',
    visible: false,
    timeRange: {
      start: [3, 45],
      end: [3, 49]
    }
  },
  {
    label: '03:50',
    visible: false,
    timeRange: {
      start: [3, 50],
      end: [3, 54]
    }
  },
  {
    label: '03:55',
    visible: false,
    timeRange: {
      start: [3, 55],
      end: [3, 59]
    }
  },
  {
    label: '04:00',
    visible: true,
    timeRange: {
      start: [4, 0],
      end: [4, 4]
    }
  },
  {
    label: '04:05',
    visible: false,
    timeRange: {
      start: [4, 5],
      end: [4, 9]
    }
  },
  {
    label: '04:10',
    visible: false,
    timeRange: {
      start: [4, 10],
      end: [4, 14]
    }
  },
  {
    label: '04:15',
    visible: false,
    timeRange: {
      start: [4, 15],
      end: [4, 19]
    }
  },
  {
    label: '04:20',
    visible: false,
    timeRange: {
      start: [4, 20],
      end: [4, 24]
    }
  },
  {
    label: '04:25',
    visible: false,
    timeRange: {
      start: [4, 25],
      end: [4, 29]
    }
  },
  {
    label: '04:30',
    visible: true,
    timeRange: {
      start: [4, 30],
      end: [4, 34]
    }
  },
  {
    label: '04:35',
    visible: false,
    timeRange: {
      start: [4, 35],
      end: [4, 39]
    }
  },
  {
    label: '04:40',
    visible: false,
    timeRange: {
      start: [4, 40],
      end: [4, 44]
    }
  },
  {
    label: '04:45',
    visible: false,
    timeRange: {
      start: [4, 45],
      end: [4, 49]
    }
  },
  {
    label: '04:50',
    visible: false,
    timeRange: {
      start: [4, 50],
      end: [4, 54]
    }
  },
  {
    label: '04:55',
    visible: false,
    timeRange: {
      start: [4, 55],
      end: [4, 59]
    }
  },
  {
    label: '05:00',
    visible: true,
    timeRange: {
      start: [5, 0],
      end: [5, 4]
    }
  },
  {
    label: '05:05',
    visible: false,
    timeRange: {
      start: [5, 5],
      end: [5, 9]
    }
  },
  {
    label: '05:10',
    visible: false,
    timeRange: {
      start: [5, 10],
      end: [5, 14]
    }
  },
  {
    label: '05:15',
    visible: false,
    timeRange: {
      start: [5, 15],
      end: [5, 19]
    }
  },
  {
    label: '05:20',
    visible: false,
    timeRange: {
      start: [5, 20],
      end: [5, 24]
    }
  },
  {
    label: '05:25',
    visible: false,
    timeRange: {
      start: [5, 25],
      end: [5, 29]
    }
  },
  {
    label: '05:30',
    visible: true,
    timeRange: {
      start: [5, 30],
      end: [5, 34]
    }
  },
  {
    label: '05:35',
    visible: false,
    timeRange: {
      start: [5, 35],
      end: [5, 39]
    }
  },
  {
    label: '05:40',
    visible: false,
    timeRange: {
      start: [5, 40],
      end: [5, 44]
    }
  },
  {
    label: '05:45',
    visible: false,
    timeRange: {
      start: [5, 45],
      end: [5, 49]
    }
  },
  {
    label: '05:50',
    visible: false,
    timeRange: {
      start: [5, 50],
      end: [5, 54]
    }
  },
  {
    label: '05:55',
    visible: false,
    timeRange: {
      start: [5, 55],
      end: [5, 59]
    }
  },
  {
    label: '06:00',
    visible: true,
    timeRange: {
      start: [6, 0],
      end: [6, 4]
    }
  },
  {
    label: '06:05',
    visible: false,
    timeRange: {
      start: [6, 5],
      end: [6, 9]
    }
  },
  {
    label: '06:10',
    visible: false,
    timeRange: {
      start: [6, 10],
      end: [6, 14]
    }
  },
  {
    label: '06:15',
    visible: false,
    timeRange: {
      start: [6, 15],
      end: [6, 19]
    }
  },
  {
    label: '06:20',
    visible: false,
    timeRange: {
      start: [6, 20],
      end: [6, 24]
    }
  },
  {
    label: '06:25',
    visible: false,
    timeRange: {
      start: [6, 25],
      end: [6, 29]
    }
  },
  {
    label: '06:30',
    visible: true,
    timeRange: {
      start: [6, 30],
      end: [6, 34]
    }
  },
  {
    label: '06:35',
    visible: false,
    timeRange: {
      start: [6, 35],
      end: [6, 39]
    }
  },
  {
    label: '06:40',
    visible: false,
    timeRange: {
      start: [6, 40],
      end: [6, 44]
    }
  },
  {
    label: '06:45',
    visible: false,
    timeRange: {
      start: [6, 45],
      end: [6, 49]
    }
  },
  {
    label: '06:50',
    visible: false,
    timeRange: {
      start: [6, 50],
      end: [6, 54]
    }
  },
  {
    label: '06:55',
    visible: false,
    timeRange: {
      start: [6, 55],
      end: [6, 59]
    }
  },
  {
    label: '07:00',
    visible: true,
    timeRange: {
      start: [7, 0],
      end: [7, 4]
    }
  },
  {
    label: '07:05',
    visible: false,
    timeRange: {
      start: [7, 5],
      end: [7, 9]
    }
  },
  {
    label: '07:10',
    visible: false,
    timeRange: {
      start: [7, 10],
      end: [7, 14]
    }
  },
  {
    label: '07:15',
    visible: false,
    timeRange: {
      start: [7, 15],
      end: [7, 19]
    }
  },
  {
    label: '07:20',
    visible: false,
    timeRange: {
      start: [7, 20],
      end: [7, 24]
    }
  },
  {
    label: '07:25',
    visible: false,
    timeRange: {
      start: [7, 25],
      end: [7, 29]
    }
  },
  {
    label: '07:30',
    visible: true,
    timeRange: {
      start: [7, 30],
      end: [7, 34]
    }
  },
  {
    label: '07:35',
    visible: false,
    timeRange: {
      start: [7, 35],
      end: [7, 39]
    }
  },
  {
    label: '07:40',
    visible: false,
    timeRange: {
      start: [7, 40],
      end: [7, 44]
    }
  },
  {
    label: '07:45',
    visible: false,
    timeRange: {
      start: [7, 45],
      end: [7, 49]
    }
  },
  {
    label: '07:50',
    visible: false,
    timeRange: {
      start: [7, 50],
      end: [7, 54]
    }
  },
  {
    label: '07:55',
    visible: false,
    timeRange: {
      start: [7, 55],
      end: [7, 59]
    }
  },
  {
    label: '08:00',
    visible: true,
    timeRange: {
      start: [8, 0],
      end: [8, 4]
    }
  },
  {
    label: '08:05',
    visible: false,
    timeRange: {
      start: [8, 5],
      end: [8, 9]
    }
  },
  {
    label: '08:10',
    visible: false,
    timeRange: {
      start: [8, 10],
      end: [8, 14]
    }
  },
  {
    label: '08:15',
    visible: false,
    timeRange: {
      start: [8, 15],
      end: [8, 19]
    }
  },
  {
    label: '08:20',
    visible: false,
    timeRange: {
      start: [8, 20],
      end: [8, 24]
    }
  },
  {
    label: '08:25',
    visible: false,
    timeRange: {
      start: [8, 25],
      end: [8, 29]
    }
  },
  {
    label: '08:30',
    visible: true,
    timeRange: {
      start: [8, 30],
      end: [8, 34]
    }
  },
  {
    label: '08:35',
    visible: false,
    timeRange: {
      start: [8, 35],
      end: [8, 39]
    }
  },
  {
    label: '08:40',
    visible: false,
    timeRange: {
      start: [8, 40],
      end: [8, 44]
    }
  },
  {
    label: '08:45',
    visible: false,
    timeRange: {
      start: [8, 45],
      end: [8, 49]
    }
  },
  {
    label: '08:50',
    visible: false,
    timeRange: {
      start: [8, 50],
      end: [8, 54]
    }
  },
  {
    label: '08:55',
    visible: false,
    timeRange: {
      start: [8, 55],
      end: [8, 59]
    }
  },
  {
    label: '09:00',
    visible: true,
    timeRange: {
      start: [9, 0],
      end: [9, 4]
    }
  },
  {
    label: '09:05',
    visible: false,
    timeRange: {
      start: [9, 5],
      end: [9, 9]
    }
  },
  {
    label: '09:10',
    visible: false,
    timeRange: {
      start: [9, 10],
      end: [9, 14]
    }
  },
  {
    label: '09:15',
    visible: false,
    timeRange: {
      start: [9, 15],
      end: [9, 19]
    }
  },
  {
    label: '09:20',
    visible: false,
    timeRange: {
      start: [9, 20],
      end: [9, 24]
    }
  },
  {
    label: '09:25',
    visible: false,
    timeRange: {
      start: [9, 25],
      end: [9, 29]
    }
  },
  {
    label: '09:30',
    visible: true,
    timeRange: {
      start: [9, 30],
      end: [9, 34]
    }
  },
  {
    label: '09:35',
    visible: false,
    timeRange: {
      start: [9, 35],
      end: [9, 39]
    }
  },
  {
    label: '09:40',
    visible: false,
    timeRange: {
      start: [9, 40],
      end: [9, 44]
    }
  },
  {
    label: '09:45',
    visible: false,
    timeRange: {
      start: [9, 45],
      end: [9, 49]
    }
  },
  {
    label: '09:50',
    visible: false,
    timeRange: {
      start: [9, 50],
      end: [9, 54]
    }
  },
  {
    label: '09:55',
    visible: false,
    timeRange: {
      start: [9, 55],
      end: [9, 59]
    }
  },
  {
    label: '10:00',
    visible: true,
    timeRange: {
      start: [10, 0],
      end: [10, 4]
    }
  },
  {
    label: '10:05',
    visible: false,
    timeRange: {
      start: [10, 5],
      end: [10, 9]
    }
  },
  {
    label: '10:10',
    visible: false,
    timeRange: {
      start: [10, 10],
      end: [10, 14]
    }
  },
  {
    label: '10:15',
    visible: false,
    timeRange: {
      start: [10, 15],
      end: [10, 19]
    }
  },
  {
    label: '10:20',
    visible: false,
    timeRange: {
      start: [10, 20],
      end: [10, 24]
    }
  },
  {
    label: '10:25',
    visible: false,
    timeRange: {
      start: [10, 25],
      end: [10, 29]
    }
  },
  {
    label: '10:30',
    visible: true,
    timeRange: {
      start: [10, 30],
      end: [10, 34]
    }
  },
  {
    label: '10:35',
    visible: false,
    timeRange: {
      start: [10, 35],
      end: [10, 39]
    }
  },
  {
    label: '10:40',
    visible: false,
    timeRange: {
      start: [10, 40],
      end: [10, 44]
    }
  },
  {
    label: '10:45',
    visible: false,
    timeRange: {
      start: [10, 45],
      end: [10, 49]
    }
  },
  {
    label: '10:50',
    visible: false,
    timeRange: {
      start: [10, 50],
      end: [10, 54]
    }
  },
  {
    label: '10:55',
    visible: false,
    timeRange: {
      start: [10, 55],
      end: [10, 59]
    }
  },
  {
    label: '11:00',
    visible: true,
    timeRange: {
      start: [11, 0],
      end: [11, 4]
    }
  },
  {
    label: '11:05',
    visible: false,
    timeRange: {
      start: [11, 5],
      end: [11, 9]
    }
  },
  {
    label: '11:10',
    visible: false,
    timeRange: {
      start: [11, 10],
      end: [11, 14]
    }
  },
  {
    label: '11:15',
    visible: false,
    timeRange: {
      start: [11, 15],
      end: [11, 19]
    }
  },
  {
    label: '11:20',
    visible: false,
    timeRange: {
      start: [11, 20],
      end: [11, 24]
    }
  },
  {
    label: '11:25',
    visible: false,
    timeRange: {
      start: [11, 25],
      end: [11, 29]
    }
  },
  {
    label: '11:30',
    visible: true,
    timeRange: {
      start: [11, 30],
      end: [11, 34]
    }
  },
  {
    label: '11:35',
    visible: false,
    timeRange: {
      start: [11, 35],
      end: [11, 39]
    }
  },
  {
    label: '11:40',
    visible: false,
    timeRange: {
      start: [11, 40],
      end: [11, 44]
    }
  },
  {
    label: '11:45',
    visible: false,
    timeRange: {
      start: [11, 45],
      end: [11, 49]
    }
  },
  {
    label: '11:50',
    visible: false,
    timeRange: {
      start: [11, 50],
      end: [11, 54]
    }
  },
  {
    label: '11:55',
    visible: false,
    timeRange: {
      start: [11, 55],
      end: [11, 59]
    }
  },
  {
    label: '12:00',
    visible: true,
    timeRange: {
      start: [12, 0],
      end: [12, 4]
    }
  },
  {
    label: '12:05',
    visible: false,
    timeRange: {
      start: [12, 5],
      end: [12, 9]
    }
  },
  {
    label: '12:10',
    visible: false,
    timeRange: {
      start: [12, 10],
      end: [12, 14]
    }
  },
  {
    label: '12:15',
    visible: false,
    timeRange: {
      start: [12, 15],
      end: [12, 19]
    }
  },
  {
    label: '12:20',
    visible: false,
    timeRange: {
      start: [12, 20],
      end: [12, 24]
    }
  },
  {
    label: '12:25',
    visible: false,
    timeRange: {
      start: [12, 25],
      end: [12, 29]
    }
  },
  {
    label: '12:30',
    visible: true,
    timeRange: {
      start: [12, 30],
      end: [12, 34]
    }
  },
  {
    label: '12:35',
    visible: false,
    timeRange: {
      start: [12, 35],
      end: [12, 39]
    }
  },
  {
    label: '12:40',
    visible: false,
    timeRange: {
      start: [12, 40],
      end: [12, 44]
    }
  },
  {
    label: '12:45',
    visible: false,
    timeRange: {
      start: [12, 45],
      end: [12, 49]
    }
  },
  {
    label: '12:50',
    visible: false,
    timeRange: {
      start: [12, 50],
      end: [12, 54]
    }
  },
  {
    label: '12:55',
    visible: false,
    timeRange: {
      start: [12, 55],
      end: [12, 59]
    }
  },
  {
    label: '13:00',
    visible: true,
    timeRange: {
      start: [13, 0],
      end: [13, 4]
    }
  },
  {
    label: '13:05',
    visible: false,
    timeRange: {
      start: [13, 5],
      end: [13, 9]
    }
  },
  {
    label: '13:10',
    visible: false,
    timeRange: {
      start: [13, 10],
      end: [13, 14]
    }
  },
  {
    label: '13:15',
    visible: false,
    timeRange: {
      start: [13, 15],
      end: [13, 19]
    }
  },
  {
    label: '13:20',
    visible: false,
    timeRange: {
      start: [13, 20],
      end: [13, 24]
    }
  },
  {
    label: '13:25',
    visible: false,
    timeRange: {
      start: [13, 25],
      end: [13, 29]
    }
  },
  {
    label: '13:30',
    visible: true,
    timeRange: {
      start: [13, 30],
      end: [13, 34]
    }
  },
  {
    label: '13:35',
    visible: false,
    timeRange: {
      start: [13, 35],
      end: [13, 39]
    }
  },
  {
    label: '13:40',
    visible: false,
    timeRange: {
      start: [13, 40],
      end: [13, 44]
    }
  },
  {
    label: '13:45',
    visible: false,
    timeRange: {
      start: [13, 45],
      end: [13, 49]
    }
  },
  {
    label: '13:50',
    visible: false,
    timeRange: {
      start: [13, 50],
      end: [13, 54]
    }
  },
  {
    label: '13:55',
    visible: false,
    timeRange: {
      start: [13, 55],
      end: [13, 59]
    }
  },
  {
    label: '14:00',
    visible: true,
    timeRange: {
      start: [14, 0],
      end: [14, 4]
    }
  },
  {
    label: '14:05',
    visible: false,
    timeRange: {
      start: [14, 5],
      end: [14, 9]
    }
  },
  {
    label: '14:10',
    visible: false,
    timeRange: {
      start: [14, 10],
      end: [14, 14]
    }
  },
  {
    label: '14:15',
    visible: false,
    timeRange: {
      start: [14, 15],
      end: [14, 19]
    }
  },
  {
    label: '14:20',
    visible: false,
    timeRange: {
      start: [14, 20],
      end: [14, 24]
    }
  },
  {
    label: '14:25',
    visible: false,
    timeRange: {
      start: [14, 25],
      end: [14, 29]
    }
  },
  {
    label: '14:30',
    visible: true,
    timeRange: {
      start: [14, 30],
      end: [14, 34]
    }
  },
  {
    label: '14:35',
    visible: false,
    timeRange: {
      start: [14, 35],
      end: [14, 39]
    }
  },
  {
    label: '14:40',
    visible: false,
    timeRange: {
      start: [14, 40],
      end: [14, 44]
    }
  },
  {
    label: '14:45',
    visible: false,
    timeRange: {
      start: [14, 45],
      end: [14, 49]
    }
  },
  {
    label: '14:50',
    visible: false,
    timeRange: {
      start: [14, 50],
      end: [14, 54]
    }
  },
  {
    label: '14:55',
    visible: false,
    timeRange: {
      start: [14, 55],
      end: [14, 59]
    }
  },
  {
    label: '15:00',
    visible: true,
    timeRange: {
      start: [15, 0],
      end: [15, 4]
    }
  },
  {
    label: '15:05',
    visible: false,
    timeRange: {
      start: [15, 5],
      end: [15, 9]
    }
  },
  {
    label: '15:10',
    visible: false,
    timeRange: {
      start: [15, 10],
      end: [15, 14]
    }
  },
  {
    label: '15:15',
    visible: false,
    timeRange: {
      start: [15, 15],
      end: [15, 19]
    }
  },
  {
    label: '15:20',
    visible: false,
    timeRange: {
      start: [15, 20],
      end: [15, 24]
    }
  },
  {
    label: '15:25',
    visible: false,
    timeRange: {
      start: [15, 25],
      end: [15, 29]
    }
  },
  {
    label: '15:30',
    visible: true,
    timeRange: {
      start: [15, 30],
      end: [15, 34]
    }
  },
  {
    label: '15:35',
    visible: false,
    timeRange: {
      start: [15, 35],
      end: [15, 39]
    }
  },
  {
    label: '15:40',
    visible: false,
    timeRange: {
      start: [15, 40],
      end: [15, 44]
    }
  },
  {
    label: '15:45',
    visible: false,
    timeRange: {
      start: [15, 45],
      end: [15, 49]
    }
  },
  {
    label: '15:50',
    visible: false,
    timeRange: {
      start: [15, 50],
      end: [15, 54]
    }
  },
  {
    label: '15:55',
    visible: false,
    timeRange: {
      start: [15, 55],
      end: [15, 59]
    }
  },
  {
    label: '16:00',
    visible: true,
    timeRange: {
      start: [16, 0],
      end: [16, 4]
    }
  },
  {
    label: '16:05',
    visible: false,
    timeRange: {
      start: [16, 5],
      end: [16, 9]
    }
  },
  {
    label: '16:10',
    visible: false,
    timeRange: {
      start: [16, 10],
      end: [16, 14]
    }
  },
  {
    label: '16:15',
    visible: false,
    timeRange: {
      start: [16, 15],
      end: [16, 19]
    }
  },
  {
    label: '16:20',
    visible: false,
    timeRange: {
      start: [16, 20],
      end: [16, 24]
    }
  },
  {
    label: '16:25',
    visible: false,
    timeRange: {
      start: [16, 25],
      end: [16, 29]
    }
  },
  {
    label: '16:30',
    visible: true,
    timeRange: {
      start: [16, 30],
      end: [16, 34]
    }
  },
  {
    label: '16:35',
    visible: false,
    timeRange: {
      start: [16, 35],
      end: [16, 39]
    }
  },
  {
    label: '16:40',
    visible: false,
    timeRange: {
      start: [16, 40],
      end: [16, 44]
    }
  },
  {
    label: '16:45',
    visible: false,
    timeRange: {
      start: [16, 45],
      end: [16, 49]
    }
  },
  {
    label: '16:50',
    visible: false,
    timeRange: {
      start: [16, 50],
      end: [16, 54]
    }
  },
  {
    label: '16:55',
    visible: false,
    timeRange: {
      start: [16, 55],
      end: [16, 59]
    }
  },
  {
    label: '17:00',
    visible: true,
    timeRange: {
      start: [17, 0],
      end: [17, 4]
    }
  },
  {
    label: '17:05',
    visible: false,
    timeRange: {
      start: [17, 5],
      end: [17, 9]
    }
  },
  {
    label: '17:10',
    visible: false,
    timeRange: {
      start: [17, 10],
      end: [17, 14]
    }
  },
  {
    label: '17:15',
    visible: false,
    timeRange: {
      start: [17, 15],
      end: [17, 19]
    }
  },
  {
    label: '17:20',
    visible: false,
    timeRange: {
      start: [17, 20],
      end: [17, 24]
    }
  },
  {
    label: '17:25',
    visible: false,
    timeRange: {
      start: [17, 25],
      end: [17, 29]
    }
  },
  {
    label: '17:30',
    visible: true,
    timeRange: {
      start: [17, 30],
      end: [17, 34]
    }
  },
  {
    label: '17:35',
    visible: false,
    timeRange: {
      start: [17, 35],
      end: [17, 39]
    }
  },
  {
    label: '17:40',
    visible: false,
    timeRange: {
      start: [17, 40],
      end: [17, 44]
    }
  },
  {
    label: '17:45',
    visible: false,
    timeRange: {
      start: [17, 45],
      end: [17, 49]
    }
  },
  {
    label: '17:50',
    visible: false,
    timeRange: {
      start: [17, 50],
      end: [17, 54]
    }
  },
  {
    label: '17:55',
    visible: false,
    timeRange: {
      start: [17, 55],
      end: [17, 59]
    }
  },
  {
    label: '18:00',
    visible: true,
    timeRange: {
      start: [18, 0],
      end: [18, 4]
    }
  },
  {
    label: '18:05',
    visible: false,
    timeRange: {
      start: [18, 5],
      end: [18, 9]
    }
  },
  {
    label: '18:10',
    visible: false,
    timeRange: {
      start: [18, 10],
      end: [18, 14]
    }
  },
  {
    label: '18:15',
    visible: false,
    timeRange: {
      start: [18, 15],
      end: [18, 19]
    }
  },
  {
    label: '18:20',
    visible: false,
    timeRange: {
      start: [18, 20],
      end: [18, 24]
    }
  },
  {
    label: '18:25',
    visible: false,
    timeRange: {
      start: [18, 25],
      end: [18, 29]
    }
  },
  {
    label: '18:30',
    visible: true,
    timeRange: {
      start: [18, 30],
      end: [18, 34]
    }
  },
  {
    label: '18:35',
    visible: false,
    timeRange: {
      start: [18, 35],
      end: [18, 39]
    }
  },
  {
    label: '18:40',
    visible: false,
    timeRange: {
      start: [18, 40],
      end: [18, 44]
    }
  },
  {
    label: '18:45',
    visible: false,
    timeRange: {
      start: [18, 45],
      end: [18, 49]
    }
  },
  {
    label: '18:50',
    visible: false,
    timeRange: {
      start: [18, 50],
      end: [18, 54]
    }
  },
  {
    label: '18:55',
    visible: false,
    timeRange: {
      start: [18, 55],
      end: [18, 59]
    }
  },
  {
    label: '19:00',
    visible: true,
    timeRange: {
      start: [19, 0],
      end: [19, 4]
    }
  },
  {
    label: '19:05',
    visible: false,
    timeRange: {
      start: [19, 5],
      end: [19, 9]
    }
  },
  {
    label: '19:10',
    visible: false,
    timeRange: {
      start: [19, 10],
      end: [19, 14]
    }
  },
  {
    label: '19:15',
    visible: false,
    timeRange: {
      start: [19, 15],
      end: [19, 19]
    }
  },
  {
    label: '19:20',
    visible: false,
    timeRange: {
      start: [19, 20],
      end: [19, 24]
    }
  },
  {
    label: '19:25',
    visible: false,
    timeRange: {
      start: [19, 25],
      end: [19, 29]
    }
  },
  {
    label: '19:30',
    visible: true,
    timeRange: {
      start: [19, 30],
      end: [19, 34]
    }
  },
  {
    label: '19:35',
    visible: false,
    timeRange: {
      start: [19, 35],
      end: [19, 39]
    }
  },
  {
    label: '19:40',
    visible: false,
    timeRange: {
      start: [19, 40],
      end: [19, 44]
    }
  },
  {
    label: '19:45',
    visible: false,
    timeRange: {
      start: [19, 45],
      end: [19, 49]
    }
  },
  {
    label: '19:50',
    visible: false,
    timeRange: {
      start: [19, 50],
      end: [19, 54]
    }
  },
  {
    label: '19:55',
    visible: false,
    timeRange: {
      start: [19, 55],
      end: [19, 59]
    }
  },
  {
    label: '20:00',
    visible: true,
    timeRange: {
      start: [20, 0],
      end: [20, 4]
    }
  },
  {
    label: '20:05',
    visible: false,
    timeRange: {
      start: [20, 5],
      end: [20, 9]
    }
  },
  {
    label: '20:10',
    visible: false,
    timeRange: {
      start: [20, 10],
      end: [20, 14]
    }
  },
  {
    label: '20:15',
    visible: false,
    timeRange: {
      start: [20, 15],
      end: [20, 19]
    }
  },
  {
    label: '20:20',
    visible: false,
    timeRange: {
      start: [20, 20],
      end: [20, 24]
    }
  },
  {
    label: '20:25',
    visible: false,
    timeRange: {
      start: [20, 25],
      end: [20, 29]
    }
  },
  {
    label: '20:30',
    visible: true,
    timeRange: {
      start: [20, 30],
      end: [20, 34]
    }
  },
  {
    label: '20:35',
    visible: false,
    timeRange: {
      start: [20, 35],
      end: [20, 39]
    }
  },
  {
    label: '20:40',
    visible: false,
    timeRange: {
      start: [20, 40],
      end: [20, 44]
    }
  },
  {
    label: '20:45',
    visible: false,
    timeRange: {
      start: [20, 45],
      end: [20, 49]
    }
  },
  {
    label: '20:50',
    visible: false,
    timeRange: {
      start: [20, 50],
      end: [20, 54]
    }
  },
  {
    label: '20:55',
    visible: false,
    timeRange: {
      start: [20, 55],
      end: [20, 59]
    }
  },
  {
    label: '21:00',
    visible: true,
    timeRange: {
      start: [21, 0],
      end: [21, 4]
    }
  },
  {
    label: '21:05',
    visible: false,
    timeRange: {
      start: [21, 5],
      end: [21, 9]
    }
  },
  {
    label: '21:10',
    visible: false,
    timeRange: {
      start: [21, 10],
      end: [21, 14]
    }
  },
  {
    label: '21:15',
    visible: false,
    timeRange: {
      start: [21, 15],
      end: [21, 19]
    }
  },
  {
    label: '21:20',
    visible: false,
    timeRange: {
      start: [21, 20],
      end: [21, 24]
    }
  },
  {
    label: '21:25',
    visible: false,
    timeRange: {
      start: [21, 25],
      end: [21, 29]
    }
  },
  {
    label: '21:30',
    visible: true,
    timeRange: {
      start: [21, 30],
      end: [21, 34]
    }
  },
  {
    label: '21:35',
    visible: false,
    timeRange: {
      start: [21, 35],
      end: [21, 39]
    }
  },
  {
    label: '21:40',
    visible: false,
    timeRange: {
      start: [21, 40],
      end: [21, 44]
    }
  },
  {
    label: '21:45',
    visible: false,
    timeRange: {
      start: [21, 45],
      end: [21, 49]
    }
  },
  {
    label: '21:50',
    visible: false,
    timeRange: {
      start: [21, 50],
      end: [21, 54]
    }
  },
  {
    label: '21:55',
    visible: false,
    timeRange: {
      start: [21, 55],
      end: [21, 59]
    }
  },
  {
    label: '22:00',
    visible: true,
    timeRange: {
      start: [22, 0],
      end: [22, 4]
    }
  },
  {
    label: '22:05',
    visible: false,
    timeRange: {
      start: [22, 5],
      end: [22, 9]
    }
  },
  {
    label: '22:10',
    visible: false,
    timeRange: {
      start: [22, 10],
      end: [22, 14]
    }
  },
  {
    label: '22:15',
    visible: false,
    timeRange: {
      start: [22, 15],
      end: [22, 19]
    }
  },
  {
    label: '22:20',
    visible: false,
    timeRange: {
      start: [22, 20],
      end: [22, 24]
    }
  },
  {
    label: '22:25',
    visible: false,
    timeRange: {
      start: [22, 25],
      end: [22, 29]
    }
  },
  {
    label: '22:30',
    visible: true,
    timeRange: {
      start: [22, 30],
      end: [22, 34]
    }
  },
  {
    label: '22:35',
    visible: false,
    timeRange: {
      start: [22, 35],
      end: [22, 39]
    }
  },
  {
    label: '22:40',
    visible: false,
    timeRange: {
      start: [22, 40],
      end: [22, 44]
    }
  },
  {
    label: '22:45',
    visible: false,
    timeRange: {
      start: [22, 45],
      end: [22, 49]
    }
  },
  {
    label: '22:50',
    visible: false,
    timeRange: {
      start: [22, 50],
      end: [22, 54]
    }
  },
  {
    label: '22:55',
    visible: false,
    timeRange: {
      start: [22, 55],
      end: [22, 59]
    }
  },
  {
    label: '23:00',
    visible: true,
    timeRange: {
      start: [23, 0],
      end: [23, 4]
    }
  },
  {
    label: '23:05',
    visible: false,
    timeRange: {
      start: [23, 5],
      end: [23, 9]
    }
  },
  {
    label: '23:10',
    visible: false,
    timeRange: {
      start: [23, 10],
      end: [23, 14]
    }
  },
  {
    label: '23:15',
    visible: false,
    timeRange: {
      start: [23, 15],
      end: [23, 19]
    }
  },
  {
    label: '23:20',
    visible: false,
    timeRange: {
      start: [23, 20],
      end: [23, 24]
    }
  },
  {
    label: '23:25',
    visible: false,
    timeRange: {
      start: [23, 25],
      end: [23, 29]
    }
  },
  {
    label: '23:30',
    visible: true,
    timeRange: {
      start: [23, 30],
      end: [23, 34]
    }
  },
  {
    label: '23:35',
    visible: false,
    timeRange: {
      start: [23, 35],
      end: [23, 39]
    }
  },
  {
    label: '23:40',
    visible: false,
    timeRange: {
      start: [23, 40],
      end: [23, 44]
    }
  },
  {
    label: '23:45',
    visible: false,
    timeRange: {
      start: [23, 45],
      end: [23, 49]
    }
  },
  {
    label: '23:50',
    visible: false,
    timeRange: {
      start: [23, 50],
      end: [23, 54]
    }
  },
  {
    label: '23:55',
    visible: false,
    timeRange: {
      start: [23, 55],
      end: [23, 59]
    }
  }
];
