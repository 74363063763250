import { Component, OnInit, ViewChild, ElementRef, Renderer2, ViewChildren, AfterViewInit, ChangeDetectionStrategy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { BehaviorSubject, Observable, combineLatest, filter, first, map, of, switchMap, tap, timer } from 'rxjs';

import { AppSettingsProvider } from '@app/app/app.settings';
import { Advertisement, FitnessCenter, DaySection, TimeSegment, TrainingSession } from '@app/interfaces';
import { ServerProvider, ContextMenuProvider } from '@app/providers';
import { TrainingSessionDialogComponent, AdvertisementDialogComponent } from '@app/shared/components';
import { DaySections, TimeSegments } from '@app/shared/constants';

@Component({
  selector: 'app-training-sessions',
  templateUrl: './training-sessions.component.html',
  styleUrls: ['./training-sessions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrainingSessionsComponent implements OnInit, AfterViewInit {
  @ViewChild('horizontalScroller') horizontalScroller?: ElementRef;
  @ViewChild('verticalScroller') verticalScroller?: ElementRef;
  @ViewChildren('sectionElements') sectionElements: any;
  sectionWidth = 250;
  daySections$ = new BehaviorSubject<DaySection[]>(DaySections);
  timeSegments: Array<TimeSegment> = TimeSegments;
  fitnessCenters: Array<FitnessCenter> = [];
  scrollLeft = 0;
  currentCenter: FitnessCenter | null = null;
  dateModifier = 0;
  private readonly refreshTimer = 300000;
  private language = 'en-US';
  private sessionsLoading = new BehaviorSubject<boolean>(false);

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private server: ServerProvider,
    private appSettings: AppSettingsProvider,
    private contextMenu: ContextMenuProvider,
    private renderer: Renderer2,
    private dialog: MatDialog
  ) {
    this.appSettings.get().then((settings) => (this.language = settings.language));
    this.currentCenter = this.server.currentCenter;
  }

  ngOnInit(): void {
    this.loadSessionsSilently();
    this.getCenters()
      .then(() => this.getSessions())
      .catch((err) => console.log(err));
    this.onSessionSelected();
  }

  ngAfterViewInit(): void {
    timer(0).subscribe(() => {
      this.scrollTodayIntoView();
      this.scrollCurrentTimeIntoView();
    });
  }

  dayName(day: DaySection): string {
    const amount = this.dateModifier * this.daySections$.getValue().length;
    let date = new Date();
    if (day.modifier === 'plus') {
      date.setDate(date.getDate() + day.day + amount);
    } else if (day.modifier === 'minus') {
      date.setDate(date.getDate() - day.day + amount);
    }
    return date.toLocaleDateString(this.language, { weekday: 'long' });
  }

  dateString(day: DaySection): string {
    const amount = this.dateModifier * this.daySections$.getValue().length;
    let dateString: string;
    let date = new Date();
    if (day.modifier === 'plus') {
      date.setDate(date.getDate() + day.day + amount);
    } else if (day.modifier === 'minus') {
      date.setDate(date.getDate() - day.day + amount);
    }
    const month = (): string => {
      let d: string = (date.getMonth() + 1).toString();
      if (d.length === 1) {
        return '0' + d;
      } else {
        return d;
      }
    };
    const monthDate = (): string => {
      let d: string = date.getDate().toString();
      if (d.length === 1) {
        return '0' + d;
      } else {
        return d;
      }
    };
    dateString = date.getFullYear() + '-' + month() + '-' + monthDate();
    return dateString;
  }

  showMoreSessions(sessions: Array<any>, timeSegment: TimeSegment, daySection: DaySection, event: any): void {
    setTimeout(() => {
      this.contextMenu.show(event.x, event.y, sessions, timeSegment, daySection, this.dateModifier);
    }, 100);
  }

  trackBy(index: number, item: any): number {
    return index;
  }

  isInThePast(daySection: DaySection): boolean {
    if (this.dateModifier === 0 && daySection.modifier === 'minus') {
      return true;
    } else {
      return false;
    }
  }

  isToday(daySection: DaySection): boolean {
    if (daySection.modifier === 'plus' && daySection.day === 0 && this.dateModifier === 0) {
      return true;
    } else {
      return false;
    }
  }

  isCurrentTime(daySection: DaySection, timeSegment: TimeSegment): boolean {
    if (this.isToday(daySection) && timeSegment.visible) {
      let rangeNumbers = [0, 0];
      const currentTime: Array<number> = [new Date().getHours(), new Date().getMinutes()];
      if (timeSegment.timeRange.end[1] <= 4) {
        rangeNumbers[0] = 0;
        rangeNumbers[1] = 29;
      } else {
        rangeNumbers[0] = 30;
        rangeNumbers[1] = 59;
      }
      if (
        timeSegment.timeRange.start[0] === currentTime[0] &&
        timeSegment.timeRange.start[1] <= currentTime[1] &&
        timeSegment.timeRange.end[0] === currentTime[0] &&
        rangeNumbers[0] <= currentTime[1] &&
        rangeNumbers[1] >= currentTime[1]
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  rangeSelected(range: number): void {
    this.dateModifier = range;
    this.getSessions();
  }

  scrollToLeft(): void {
    this.scrollLeft = this.scrollLeft - this.sectionWidth;
    if (this.scrollLeft < 0) {
      this.scrollLeft = 0;
    }
    this.renderer.setStyle(this.horizontalScroller?.nativeElement, 'transform', 'translate3d(-' + this.scrollLeft + 'px, 0px, 0px)');
  }

  scrollToRight(): void {
    this.scrollLeft = this.scrollLeft + this.sectionWidth;
    const maxLeft = this.daySections$.getValue().length * this.sectionWidth - (window as any).innerWidth + 50;
    if (this.scrollLeft > maxLeft) {
      this.scrollLeft = maxLeft;
    }
    this.renderer.setStyle(this.horizontalScroller?.nativeElement, 'transform', 'translate3d(-' + this.scrollLeft + 'px, 0px, 0px)');
  }

  isTestSession(session: TrainingSession): boolean {
    return session && session.TestBookings !== '0';
  }

  selectSession(session: TrainingSession): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        t: session.TrainingSessionGUID,
        d: session.Date,
      },
      queryParamsHandling: 'merge',
    });
  }

  get mobileUrl(): string {
    return `https://m.${location.host}${location.pathname}${location.search}`;
  }

  centerSelected(center: FitnessCenter): void {
    this.currentCenter = center;
    this.router.navigate([center.ActivatedGUID, 'sessions'], { queryParamsHandling: 'preserve' });
    this.getSessions();
  }

  private viewSession(session: TrainingSession): Observable<Advertisement> {
    return this.dialog
      .open(TrainingSessionDialogComponent, {
        width: '300px',
        maxWidth: '90vw',
        maxHeight: '90vh',
        data: session,
        autoFocus: false,
      })
      .afterClosed();
  }

  private showAdvertisement(ad: Advertisement): void {
    this.dialog.open(AdvertisementDialogComponent, {
      width: '500px',
      maxWidth: '90vw',
      data: ad,
      disableClose: true,
    });
  }

  private scrollTodayIntoView(): void {
    const left = this.sectionElements._results[2].nativeElement.getBoundingClientRect().left;
    if (left + this.sectionWidth > (window as any).innerWidth) {
      let overhang = 0;
      const amount = left / (this.daySections$.getValue().length * this.sectionWidth);
      let toLeft = amount * this.sectionWidth;
      if (amount > 0) {
        overhang = left - toLeft;
      }
      this.scrollLeft = overhang + toLeft - 50;
      this.renderer.setStyle(this.horizontalScroller?.nativeElement, 'transform', 'translate3d(-' + this.scrollLeft + 'px, 0px, 0px)');
    }
  }

  scrollCurrentTimeIntoView(): void {
    const currentTime: Array<number> = [new Date().getHours(), new Date().getMinutes()];
    this.timeSegments.forEach((segment, i) => {
      if (segment.visible) {
        if (
          segment.timeRange.start[0] === currentTime[0] &&
          segment.timeRange.start[1] <= currentTime[1] &&
          segment.timeRange.end[0] === currentTime[0] &&
          new Date().setHours(segment.timeRange.end[0], 29, 0, 0) >= currentTime[1]
        ) {
          const scroll = i * 17;
          this.renderer.setProperty(this.verticalScroller?.nativeElement, 'scrollTop', scroll);
        }
      }
    });
  }

  private loadSessionsSilently(): void {
    const loadData = (): Observable<void> => {
      return timer(this.refreshTimer).pipe(
        tap(() => this.getSessions()),
        switchMap(() => loadData())
      );
    };

    loadData().subscribe();
  }

  private getCenters(): Promise<void> {
    return new Promise((resolve) => {
      this.server.centers.pipe(first((fitnessCenters) => fitnessCenters != null)).subscribe((fitnessCenters) => {
        this.fitnessCenters = fitnessCenters;
        resolve();
      });
    });
  }

  private getSessions(): Promise<void> {
    if (!this.dialog.openDialogs.length) {
      this.sessionsLoading.next(true);
      return new Promise((resolve) => {
        if (this.currentCenter) {
          // this.daySections.forEach((section) => (section.sessions = []));
          this.server
            .getAllSessions(this.daySections$.getValue(), this.currentCenter, this.dateModifier)
            .then((daySectionsWithSessions) => {
              this.daySections$.next(daySectionsWithSessions);
              this.sessionsLoading.next(false);
              resolve();
            });
        }
        resolve();
      });
    }
    return Promise.resolve();
  }

  private removeSelectedSession(): void {
    const queryParams = { ...this.route.snapshot.queryParams };
    delete queryParams.t;
    delete queryParams.d;
    this.router.navigate([], { relativeTo: this.route, queryParams });
  }

  private onSessionSelected(): void {
    combineLatest([this.route.queryParamMap, this.sessionsLoading.pipe(filter((loading) => loading === false))])
      .pipe(
        map(([queryParams, sessionsLoading]: [ParamMap, boolean]) => {
          const trainingSessionGuid = queryParams.get('t');
          const date = queryParams.get('d');
          let trainingSession: TrainingSession | undefined;
          if (trainingSessionGuid && date) {
            this.daySections$.getValue().forEach((daySection) => {
              const session =
                daySection &&
                daySection.sessions &&
                daySection.sessions.find((s) => s.TrainingSessionGUID === trainingSessionGuid && s.Date === date);
              if (session) {
                trainingSession = session;
              }
            });
          }
          return trainingSession;
        }),
        switchMap((trainingSession) => (trainingSession ? this.viewSession(trainingSession) : of(null)))
      )
      .subscribe((advertisement) => {
        if (advertisement) {
          this.showAdvertisement(advertisement);
        } else {
          this.removeSelectedSession();
        }
      });
  }
}
