import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { FitnessCenter } from '@app/interfaces';
import { ServerProvider } from '@app/providers';

@Component({
  template: `
    <ng-container *ngIf="centers$ | async as centers">
      <div class="absolute-fill bg-light-grey" fxLayout="column" fxLayoutAlign="center center">
        <div class="center-select-container mat-elevation-z2" *ngIf="(processing$ | async) === false" fxLayout="column">
          <div class="title-container">
            <p class="mat-title">
              <strong>Välj ditt gym</strong>
            </p>
          </div>

          <div class="content-container" fxFlex>
            <app-select-center [centers]="centers" (centerSelected)="onCenterSelected($event)"> </app-select-center>
          </div>
        </div>
      </div>
    </ng-container>
  `,
  styles: [
    `
      .center-select-container {
        height: 85vh;
        width: 85vw;
        background-color: #ffffff;
        border-radius: 3px;
        margin: 15px;
        overflow-y: auto;
      }

      .title-container {
        padding-top: 10px;
        padding-left: 10px;
        padding-right: 10px;
        text-align: center;
        margin-top: 50px;
      }

      .content-container {
        padding-bottom: 10px;
        padding-left: 10px;
        padding-right: 10px;
        margin: 0 auto;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectCenterContainer implements OnInit {
  processing$ = this.server.processing;
  centers$ = this.server.centers;

  constructor(private server: ServerProvider, private router: Router) {}

  ngOnInit(): void {
    this.server.loadCenters();
  }

  onCenterSelected(center: FitnessCenter): void {
    this.server.setCurrentCenter(center);
    this.router.navigate([center.ActivatedGUID, 'sessions'], { queryParamsHandling: 'preserve' });
  }
}
