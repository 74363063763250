import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject, Subject, takeUntil, timer } from 'rxjs';

import { Advertisement } from '@app/interfaces';

@Component({
  selector: 'app-advertisement-dialog',
  templateUrl: './advertisement-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdvertisementDialogComponent implements OnInit, OnDestroy {
  timer$ = new BehaviorSubject<number>(10);

  private readonly destroyed$ = new Subject<void>();

  constructor(private dialog: MatDialogRef<AdvertisementDialogComponent>, @Inject(MAT_DIALOG_DATA) public ad: Advertisement) {}

  ngOnInit(): void {
    this.countDown();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  viewAd(): void {
    (window as any).open(this.ad.url);
  }

  private countDown(): void {
    timer(1000)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: () => {
          if (this.timer$.getValue() > 0) {
            this.timer$.next(this.timer$.getValue() - 1);
            this.countDown();
          } else {
            this.dialog.close();
          }
        },
      });
  }
}
