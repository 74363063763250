import { Component, ElementRef, Renderer2, HostListener, ChangeDetectionStrategy } from '@angular/core';

import { DaySection, TimeSegment } from '@app/interfaces';
import { SelectedSessionProvider, ContextMenuProvider } from '@app/providers';

@Component({
  selector: 'app-context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContextMenuComponent {
  sessions: Array<any> = [];
  timeSegment: TimeSegment | null = null;
  daySection: DaySection | null = null;
  dateModifier = 0;

  constructor(
    private contextMenu: ContextMenuProvider,
    private element: ElementRef,
    private renderer: Renderer2,
    private selectedSession: SelectedSessionProvider
  ) {
    this.contextMenu.register(this);
  }

  @HostListener('document:click', ['$event'])
  private onClick(event: Event): void {
    if (!this.element.nativeElement.contains(event.target)) {
      this.hide();
    }
  }

  viewSession(session: any): void {
    this.selectedSession.select(session);
  }

  hide(): void {
    this.renderer.setStyle(this.element.nativeElement, 'opacity', 0);
    this.renderer.setStyle(this.element.nativeElement, 'pointer-events', 'none');
  }

  show(x: number, y: number, sessions: Array<any>, timeSegment: TimeSegment, daySection: DaySection, dateModifier: number): void {
    this.sessions = sessions;
    this.timeSegment = timeSegment;
    this.daySection = daySection;
    this.dateModifier = dateModifier;
    this.renderer.setStyle(this.element.nativeElement, 'left', x + 10 + 'px');
    this.renderer.setStyle(this.element.nativeElement, 'top', y + 'px');
    this.renderer.setStyle(this.element.nativeElement, 'opacity', 1);
    this.renderer.setStyle(this.element.nativeElement, 'pointer-events', 'auto');
  }

  trackBy(index: number, item: any): number {
    return index;
  }
}
