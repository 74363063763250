import { Injectable } from '@angular/core';

import { DaySection, TimeSegment } from '@app/interfaces';

@Injectable()
export class ContextMenuProvider {
  private menu: any;

  register(menu: any): void {
    this.menu = menu;
  }

  show(x: number, y: number, sessions: Array<any>, timeSegment: TimeSegment, daySection: DaySection, dateModifier: number): void {
    this.menu.show(x, y, sessions, timeSegment, daySection, dateModifier);
  }
}
