import { DaySection } from '@app/interfaces';

export const DaySections: Array<DaySection> = [
  {
    modifier: 'minus',
    day: 2,
    loading: false,
    sessions: [],
  },
  {
    modifier: 'minus',
    day: 1,
    loading: false,
    sessions: [],
  },
  {
    modifier: 'plus',
    day: 0,
    loading: false,
    sessions: [],
  },
  {
    modifier: 'plus',
    day: 1,
    loading: false,
    sessions: [],
  },
  {
    modifier: 'plus',
    day: 2,
    loading: false,
    sessions: [],
  },
  {
    modifier: 'plus',
    day: 3,
    loading: false,
    sessions: [],
  },
  {
    modifier: 'plus',
    day: 4,
    loading: false,
    sessions: [],
  },
  {
    modifier: 'plus',
    day: 5,
    loading: false,
    sessions: [],
  },
  {
    modifier: 'plus',
    day: 6,
    loading: false,
    sessions: [],
  },
  {
    modifier: 'plus',
    day: 7,
    loading: false,
    sessions: [],
  },
];
