import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';

import { FitnessCenter } from '@app/interfaces';

@Component({
  selector: 'app-select-center',
  template: `
    <div *ngFor="let center of centers" class="center" [style.textAlign]="'center'" (click)="centerSelected.emit(center)">
      <div [style.padding]="'5px'">
        <span class="mat-title">{{ center.ActivationName }}</span>
      </div>
      <mat-divider></mat-divider>
    </div>
  `,
  styles: [
    `
      :host {
        display: block;
      }

      .center {
        cursor: pointer;
        max-width: 300px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectCenterComponent {
  @Input() centers: FitnessCenter[] = [];
  @Output() centerSelected = new EventEmitter<FitnessCenter>();
}
