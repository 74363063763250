import { Component, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';

import { AppSettingsProvider } from '@app/app/app.settings';
import { DaySection } from '@app/interfaces';
import { DaySections } from '@app/shared/constants/daysections';

@Component({
  selector: 'app-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateRangePickerComponent {
  @Output() rangeSelected = new EventEmitter<number>();
  dateRanges = [DaySections, DaySections, DaySections, DaySections];
  selectedRange = 0;
  private language = 'en-US';

  constructor(private appSettings: AppSettingsProvider) {
    this.appSettings.get().then((settings) => (this.language = settings.language));
  }

  rangeLabel(range: Array<DaySection>, index: number): string {
    let startDate = new Date();
    let endDate = new Date();
    const amount = index * range.length;
    if (range[0].modifier === 'plus') {
      startDate.setDate(startDate.getDate() + range[0].day + amount);
    } else if (range[0].modifier === 'minus') {
      startDate.setDate(startDate.getDate() - range[0].day + amount);
    }
    if (range[range.length - 1].modifier === 'plus') {
      endDate.setDate(endDate.getDate() + range[range.length - 1].day + amount);
    } else if (range[range.length - 1].modifier === 'minus') {
      endDate.setDate(endDate.getDate() - range[range.length - 1].day + amount);
    }
    return (
      startDate.toLocaleDateString(this.language, { month: 'long', day: '2-digit' }) +
      ' - ' +
      endDate.toLocaleDateString(this.language, { month: 'long', day: '2-digit' })
    );
  }

  selectRange(index: number): void {
    this.selectedRange = index;
    this.rangeSelected.emit(this.selectedRange);
  }
}
