import { PipeTransform, Pipe } from '@angular/core';

import { DaySection, TimeSegment } from '@app/interfaces';
import { DaySections } from '@app/shared/constants/daysections';

@Pipe({
  name: 'sessionsWithinTimeSpan',
})
export class SessionsWithinTimeSpanPipe implements PipeTransform {
  private daySections: Array<DaySection> = DaySections;

  transform(sessions: Array<any>, timeSegment: TimeSegment | null, daySection: DaySection | null, dateModifier: number) {
    let validSessions: Array<any> = [];
    if (timeSegment && daySection) {
      sessions.forEach((session) => {
        if (this.sessionIsWithinTimespan(session, timeSegment, daySection, dateModifier)) {
          validSessions.push(session);
        }
      });
    }
    return validSessions;
  }

  private sessionIsWithinTimespan(session: any, timeSegment: TimeSegment, daySection: DaySection, dateModifier: number): boolean | null {
    const amount = dateModifier * this.daySections.length;
    let timeSegmentStart: any = new Date();
    let timeSegmentEnd: any = new Date();
    if (daySection.modifier === 'plus') {
      timeSegmentStart.setDate(timeSegmentStart.getDate() + daySection.day + amount);
      timeSegmentEnd.setDate(timeSegmentEnd.getDate() + daySection.day + amount);
    } else if (daySection.modifier === 'minus') {
      timeSegmentStart.setDate(timeSegmentStart.getDate() - daySection.day + amount);
      timeSegmentEnd.setDate(timeSegmentEnd.getDate() - daySection.day + amount);
    }
    timeSegmentStart.setHours(timeSegment.timeRange.start[0], timeSegment.timeRange.start[1], 0, 0);
    timeSegmentEnd.setHours(timeSegment.timeRange.end[0], timeSegment.timeRange.end[1], 0, 0);
    timeSegmentStart = timeSegmentStart.getTime();
    timeSegmentEnd = timeSegmentEnd.getTime();
    const sessionYear: number = parseInt(session.Date.substring(0, 4));
    const sessionMonth: number = parseInt(session.Date.substring(5, 7)) - 1;
    const sessionDay: number = parseInt(session.Date.substring(8, session.Date.length));
    const sessionHour: number = parseInt(session.StartTime.substring(0, session.StartTime.indexOf(':')));
    const sessionMinutes: number = parseInt(session.StartTime.substring(session.StartTime.indexOf(':') + 1, session.StartTime.length));
    const sessionStart = new Date(sessionYear, sessionMonth, sessionDay, sessionHour, sessionMinutes).getTime();
    if (sessionStart >= timeSegmentStart && sessionStart < timeSegmentEnd) {
      return true;
    } else {
      return null;
    }
  }
}
