import { Component, Output, EventEmitter, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-fitness-center-picker',
  templateUrl: './fitness-center-picker.component.html',
  styleUrls: ['./fitness-center-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FitnessCenterPickerComponent {
  @Output() centerSelected = new EventEmitter<any>();
  @Input() fitnessCenters: Array<any> = [];
  @Input() currentCenter!: string;

  selectCenter(index: number): void {
    this.centerSelected.emit(this.fitnessCenters[index]);
  }
}
