import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, first, map, of } from 'rxjs';

import { ServerProvider } from '@app/providers/server/server';

@Injectable()
export class CanAccessSessionsGuard implements CanActivate {
  constructor(private server: ServerProvider, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    if (!this.server.currentCenter) {
      const fitnessCenterGuid = route.paramMap.get('fitnessCenterGuid');
      return this.server.centers.pipe(
        first(),
        map((centers) => {
          const center = fitnessCenterGuid && centers && centers.find((c) => c.ActivatedGUID === fitnessCenterGuid);
          if (center) {
            this.server.setCurrentCenter(center);
            return true;
          } else {
            this.router.navigate(['select-center'], { queryParams: route.queryParams });
            return false;
          }
        })
      );
    }
    return of(true);
  }
}
