import { HttpClient } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';

import { AppSettingsProvider } from '@app/app/app.settings';

@Injectable()
export class AuthProvider {
  constructor(private appSettings: AppSettingsProvider, private ngZone: NgZone, private http: HttpClient) {}

  init(): Promise<void> {
    return new Promise((resolve, reject) =>
      this.ngZone.run(() => {
        this.appSettings.get().then((appSettings) => {
          if (appSettings.auth) {
            const params: URLSearchParams = new URLSearchParams(new URL(location.href).search);
            console.log(params);
            if (appSettings.user && appSettings.userLogin) {
              params.delete('token');
              params.delete('lg');
              window.history.pushState('', '', `${location.pathname}?${params.toString()}`);
              resolve();
            } else {
              const url = location.href.substring(0, location.href.indexOf('?'));
              params.delete('u');
              const redirectUrl = `${url}?${params.toString()}`;
              location.href = `${appSettings.authServer}?redirect_uri=${encodeURIComponent(redirectUrl)}&guid=${
                appSettings.activationCode
              }`;
            }
          } else {
            resolve();
          }
        });
      })
    );
  }
}
