import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class SelectedSessionProvider {
  private selectedSession: BehaviorSubject<any> = new BehaviorSubject(null);

  selected(): BehaviorSubject<any> {
    return this.selectedSession;
  }

  select(session: any): void {
    this.selectedSession.next(session);
  }
}
