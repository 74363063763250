import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { AppSettingsProvider } from '@app/app/app.settings';
import { Advertisement, TrainingSession, Binary } from '@app/interfaces';
import { ServerProvider } from '@app/providers';

@Component({
  selector: 'app-training-session-dialog',
  templateUrl: './training-session-dialog.component.html',
  styles: [
    `
      .test-user-text {
        text-decoration: underline;
        cursor: pointer;
        margin-bottom: 0;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrainingSessionDialogComponent implements OnInit {
  loading = false;
  bookable = false;
  user!: string;
  isAuthenticated = false;
  form!: FormGroup;

  constructor(
    private dialog: MatDialogRef<TrainingSessionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public session: TrainingSession,
    private fb: FormBuilder,
    private server: ServerProvider,
    private snackBar: MatSnackBar,
    private settings: AppSettingsProvider
  ) {
    if (this.session.Bookable === '1') {
      this.bookable = true;
    }
  }

  ngOnInit(): void {
    this.settings.get().then((settings) => {
      if (settings.user && settings.userLogin) {
        this.isAuthenticated = true;
      }
    });
    this.createForm();
  }

  close(advertisement?: Advertisement): void {
    this.dialog.close(advertisement);
  }

  get canBookAsTestUser(): boolean {
    return this.session && this.session.TestBookings !== '0' && this.session.Bookable === Binary.True;
  }

  get canSubmit(): boolean {
    if (this.loading) {
      return false;
    }
    if (this.isAuthenticated) {
      return true;
    }
    if (this.form && this.form.valid) {
      return true;
    }
    return false;
  }

  book(): void {
    this.loading = true;
    this.server
      .book(this.session.TrainingSessionGUID, this.session.Date, this.form.controls.pin.value, this.form.controls.id.value)
      .then((json) => {
        let success = null;
        if (json.Data && json.Data.Result === '0') {
          this.showMessage(json.Data.Description);
          success = true;
        } else if (json.Diagnostics) {
          this.showMessage(json.Diagnostics.Diagnostic.Description);
        }
        this.loading = false;
        if (success) {
          this.close({
            img: json.Data.ImageName,
            url: json.Data.URL,
          });
        } else {
          this.close();
        }
      })
      .catch(() => {
        this.loading = false;
        this.showMessage('Something went wrong with booking this session');
      });
  }

  createTestUser(): void {
    this.settings.get().then((settings) => {
      location.href = [
        `${settings.registerServer}`,
        `?f=${this.server.currentCenter?.ActivatedGUID}`,
        `&t=${this.session.TrainingSessionGUID}`,
        `&d=${this.session.Date}`,
      ].join('');
    });
  }

  private createForm(): void {
    this.form = this.fb.group({
      id: [null, Validators.required],
      pin: [null, Validators.required],
    });
  }

  private showMessage(text: string): void {
    this.snackBar.open(text, undefined, {
      duration: 3000,
    });
  }
}
