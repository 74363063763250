import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { CanAccessSessionsGuard } from '@app/guards';
import { SelectCenterContainer } from '@app/pages/select-center/containers';
import { TrainingSessionsComponent } from '@app/pages/training-sessions/training-sessions.component';

const appRoutes: Routes = [
  { path: '', component: SelectCenterContainer },
  { path: 'select-center', component: SelectCenterContainer },
  { path: ':fitnessCenterGuid/sessions', component: TrainingSessionsComponent, canActivate: [CanAccessSessionsGuard] },
  { path: '**', redirectTo: 'select-center' },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
})
export class AppRoutesModule {}
